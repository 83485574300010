module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-modal-routing-3/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"closeTimeoutMS":500}},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"creech-v2","schemas":{"about":{"Main":{"clients":{"type":"StructuredText","config":{"multi":"paragraph,hyperlink","allowTargetBlank":true,"label":"Clients"}},"contact":{"type":"StructuredText","config":{"multi":"paragraph,hyperlink","allowTargetBlank":true,"label":"Contact"}},"body":{"type":"Slices","fieldset":"Slice zone","config":{"labels":null,"choices":{"text_block":{"type":"Slice","fieldset":"Text block","description":"Text block","icon":"text_fields","display":"list","non-repeat":{"title":{"type":"StructuredText","config":{"single":"paragraph","label":"Title"}},"text":{"type":"StructuredText","config":{"multi":"paragraph,hyperlink","allowTargetBlank":true,"label":"Text"}}},"repeat":{}}}}}}},"clients":{"Main":{"body":{"type":"Slices","fieldset":"Slice zone","config":{"labels":null,"choices":{"media":{"type":"Slice","fieldset":"Media","description":"Image or video","icon":"image","display":"list","non-repeat":{"slug":{"type":"StructuredText","config":{"single":"paragraph","label":"Slug"}},"caption":{"type":"StructuredText","config":{"multi":"paragraph","label":"Caption"}},"desktop_media":{"type":"Link","config":{"select":null,"label":"Desktop media"}},"mobile_media":{"type":"Link","config":{"select":null,"label":"Mobile media"}}},"repeat":{}}}}}}},"private_clients":{"Main":{"slug":{"type":"StructuredText","config":{"single":"paragraph","label":"Slug"}},"body":{"type":"Slices","fieldset":"Slice zone","config":{"labels":null,"choices":{"media":{"type":"Slice","fieldset":"Media","description":"Image or video","icon":"image","display":"list","non-repeat":{"slug":{"type":"StructuredText","config":{"single":"paragraph","label":"Slug"}},"desktop_media":{"type":"Link","config":{"select":null,"label":"Desktop media"}},"mobile_media":{"type":"Link","config":{"select":null,"label":"Mobile media"}}},"repeat":{}}}}}}},"homepage":{"Main":{"caption":{"type":"StructuredText","config":{"multi":"paragraph","label":"Caption"}}}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
